import React, { useEffect, useState, createContext, useContext } from "react";
import {
  tenantAuthRefreshToken,
  tenantAuthReset,
  tenantAuthLogout,
} from "@ovrture/react-redux";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

const AuthContext = createContext();

export function AuthProvider(props) {
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(true);
  const [defaultLayout, setDefaultLayout] = useState(false);
  const { authUser, refreshToken } = useSelector((state) => ({
    authUser: state?.records?.user,
    refreshToken: state?.records?.refreshToken,
  }));

  const handleConnectionChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    const connection = getNetworkConnection();
    connection?.addEventListener("change", handleConnectionChange);
    return () => {
      connection?.removeEventListener("change", handleConnectionChange);
    };
  }, []);

  function getNetworkConnection() {
    return (
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      null
    );
  }

  // for Idle time
  const handleOnIdle = async (event) => {
    if (authUser) await dispatch(tenantAuthRefreshToken());
  };

  const handleOnActive = async (event) => {
    if (authUser) await dispatch(tenantAuthRefreshToken());
  };

  const handleOnAction = async (event) => {
    if (authUser) await dispatch(tenantAuthRefreshToken());
  };

  const { getRemainingTime, getLastIdleTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 100000,
  });

  return (
    <AuthContext.Provider
      value={{
        authUser,
        isOnline,
        //loading,
        //error,
        refreshToken,
        defaultLayout,
        setDefaultLayout,
        //tenantPermission,
      }}
      {...props}
    />
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
