import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, NotificationProvider } from "@ovrture/react-redux";
import RouteShared from "./helpers/routes/RouteShared.js";
import "./index.css";
import "./scss/app.scss";
import { AuthProvider } from "./helpers/context/AuthContext.js";
ReactDOM.render(
  <>
    <Provider store={store({})}>
      <NotificationProvider dismissTime={3000}>
        <AuthProvider>
          <RouteShared tenantId={process.env.REACT_APP_TENANT} />
        </AuthProvider>
      </NotificationProvider>
    </Provider>
  </>,
  document.getElementById("root")
);
