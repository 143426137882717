export const ScriptHandler = (scriptsArray) => {
  scriptsArray.forEach((item, index) => {
    if (item) {
      const script = document.createElement("script");
      script.src = item;
      script.nonce = "2646294A404E6352";
      script.key = index;
      script.async = true;
      document.getElementById("renderHtml").appendChild(script);
    }
  });
};

export const RemoveOldScriptAndLink = () => {
  let commonJsFile = document.querySelectorAll(`script[src*="/ui/js/"]`);
  let commonCssFile = document.querySelectorAll(`link[href*="/ui/css/"]`);
  for (let i = 0; i < commonJsFile.length; i++) {
    commonJsFile[i].remove();
  }
  for (let i = 0; i < commonCssFile.length; i++) {
    commonCssFile[i].remove();
  }
};

export const linkTagHandler = (orgId) => {
  if (process.env.REACT_APP_ACCESS_LOCAL_FILE === "true") {
    console.log("Local file access enabled");
    let linksArray = [`./assets/css/migration.css`, `./assets/css/style.css`];
    linksArray.forEach((item) => {
      const link = document.createElement("link");
      link.href = item;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    });
  }
};
