import { customVisitorCheckers } from "../checkers";
import { getLoginUrl } from "../logout";
import { getTenantOrgId } from "../migration/TenantMigration";

export const getTenantData = () => {
  return JSON.parse(
    sessionStorage.getItem(process.env.REACT_APP_DASHBOARD_SESSION_AUTH_KEY)
  );
};

export const clearTenantData = () => {
  localStorage.removeItem("tenant_user");
  localStorage.removeItem("magicPasscodeSet");
  localStorage.removeItem("TenantOrgId");
  localStorage.removeItem("menuClose");
};

export const checkToken = () => {
  let data = localStorage.getItem(
    process.env.REACT_APP_DASHBOARD_SESSION_AUTH_KEY
  );
  if (data) {
    return true;
  } else {
    window.location.href = getLoginUrl();
  }
};

export const setCustomVisit = (url) => {
  if (customVisitorCheckers?.includes(getTenantOrgId())) {
    let obj = JSON.parse(localStorage.getItem("CustomVisit")) || [];
    if (!obj?.find((f) => f === url)) {
      obj.push(url);
    }
    localStorage.setItem("CustomVisit", JSON.stringify(obj));
  }
};
export const getCustomVisit = () => {
  if (customVisitorCheckers?.includes(getTenantOrgId())) {
    return JSON.parse(localStorage.getItem("CustomVisit")) || [];
  } else return [];
};
