import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { getTenantOrgId, removeNoScriptJs } from "../migration/TenantMigration";

import PrivateRoute from "./PrivateRoute";

/**
 * Shared Route to all tenants
 * @param {*} param0
 * @returns
 */
export function RouteShared(props) {
  const CommonLogin = React.lazy(() =>
    import("../../layouts/commonLogin/commonlogin.module.layout")
  );

  const Passcode = React.lazy(() =>
    import("../../layouts/passcode/passcode.module.layout")
  );

  const Error = React.lazy(() =>
    import("../../layouts/error/error.module.layout")
  );

  const Site = React.lazy(() => import("../../layouts/site/site.module"));
  const Report = React.lazy(() => import("../../layouts/report/report.module"));

  const allRoutes = [
    {
      path: "/error",
      name: "error",
      component: Error,
      route: Route,
    },
    {
      exact: true,
      path: "/site/login",
      name: "commonLogin",
      component: CommonLogin,
      route: Route,
    },
    {
      exact: true,
      path: "/report/login",
      name: "commonLogin",
      component: CommonLogin,
      route: Route,
    },
    {
      path: "/:system/:year/:recordId",
      name: "passcode",
      component: Passcode,
      route: Route,
    },
    {
      path: "/:year/:recordId",
      name: "passcode",
      component: Passcode,
      route: Route,
    },

    {
      exact: true,
      path: "/sites",
      name: "dashboard",
      component: Site,
      route: Route,
    },
    {
      exact: true,
      path: "/reports",
      name: "dashboard",
      component: Report,
      route: Route,
    },
    {
      path: "/:recordId",
      name: "passcode",
      component: Passcode,
      route: Route,
    },
  ];

  useEffect(() => {
    if (
      window.location.href.includes("localhost:") &&
      window.location.pathname == "/"
    ) {
      window.location.href = window.location.href + "site/login";
    } else if (window.location.pathname == "/") {
      window.location.href = window.location.href + "platform/public/login";
    }
    removeNoScriptJs();
  }, []);
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <Switch>
          <Route exact path={allRoutes.map((r) => r["path"])}>
            <Switch>
              {allRoutes?.map((route, index) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
            </Switch>
          </Route>
          {/* <Route exact path="/">
            <Redirect to="/site/login" />
          </Route> */}
          {/* <Redirect
            from="/"
            to="/platform/public/login"
            render={() => window.location.reload()}
          /> */}
          {/* <Route
            exact
            path="/"
            render={() =>
              (window.location.href =
                window.location.href + "platform/public/login")
            }
          /> */}
          {/* <Redirect to="/error" /> */}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default RouteShared;
